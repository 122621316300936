import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

/*
	onMenuButtonClick
	onTopbarMenuButtonClick
	activeUser
*/
const AppTopbar = (props) => {
	const navigate = useNavigate();
	const menuRef = useRef(null);

	const menuItems = [
		{label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: () => handleLogout()}
	]

	const toDashboard = () => {
		navigate('/');
	};

	const handleLogout = () => {
		window.location.href = "/.auth/logout";
	}


	return (
		<div className="topbar clearfix">
			<div className="topbar-left">
				<button className="p-link" onClick={toDashboard}>
					<img alt="Logo"
						src="assets/layout/images/logo.png"
						className="topbar-logo"
					/>
					<div>GatesNow</div>
				</button>
			</div>
			<div className="topbar-right acaa">
				{/*<button className="p-link"
					id="menu-button"
					onClick={props.onMenuButtonClick}
				>
					<i className="pi pi-angle-left"></i>
				</button>
				<button className="p-link"
					id="topbar-menu-button"
					onClick={props.onTopbarMenuButtonClick}
				>
					<i className="pi pi-bars"></i>
				</button>*/}
				<div className="p-link user-menu">
					<Button onClick={(e) => menuRef.current.toggle(e)}
						className="menu-toggle"
						aria-controls="popup_menu"
						aria-haspopup
					>
						<div>
							<div>
								<div className="name">{props?.activeUser?.userInfo?.personalInfo?.firstName}</div>{" "}
								<div className="name">{props?.activeUser?.userInfo?.personalInfo?.lastName}</div>
							</div>
							<div className="title">{props?.activeUser?.userInfo?.personalInfo?.title}</div>
						</div>
						<img src={props?.activeUser?.userInfo?.photo} alt="User photo" />
					</Button>
					<Menu model={menuItems}
						ref={menuRef}
						id="popup_menu"
						popup
					/>
				</div>
			</div>
		</div>
	);
}

export default AppTopbar;
