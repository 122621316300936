import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserContext } from "../UserContext";
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { CalculationInterpolation } from 'sass';

export const ConcessionsGateNow = () => {
    const user = useContext(UserContext);
    const [table, setTable] = useState([]);
    const [options, setOptions] = useState({});
    const [scrollHeight, setScrollHeight] = useState('80vh');
    const [filters, setFilters] = useState({
        "SQLTenantName": { value: null, matchMode: FilterMatchMode.EQUALS },
        "SQLUnitName": { value: null, matchMode: FilterMatchMode.EQUALS },
        "SQLLocationName": { value: null, matchMode: FilterMatchMode.EQUALS },
        "PlaceName": { value: null, matchMode: FilterMatchMode.EQUALS },
        "StateDescription": { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const fetchConcessionsGateData = () => {
        const filterParams = {
            SQLTenantName: filters.SQLTenantName.value,
            SQLUnitName: filters.SQLUnitName.value,
            SQLLocationName: filters.SQLLocationName,
            PlaceName: filters.PlaceName.value,
            StateDescription: filters.StateDescription.value
        };
        user.apiCall(`getconcessionsgatenow`, { params: filterParams }).then(res => {
            const uniqueTenantNames = Array.from(new Set(res.data.map(item => item.SQLTenantName))).sort();
            const uniqueUnitNames = Array.from(new Set(res.data.map(item => item.SQLUnitName))).sort();
            const uniqueLocationNames = Array.from(new Set(res.data.map(item => item.SQLLocationName))).sort();
            const uniquePlaceNames = Array.from(new Set(res.data.map(item => item.PlaceName))).sort();
            const uniqueStateDescription = ["Open", "Open Outside Regular Hours", "Closed", "Closed During Regular Hours"];

            setOptions({
                "SQLTenantName": uniqueTenantNames,
                "SQLUnitName": uniqueUnitNames,
                "SQLLocationName": uniqueLocationNames,
                "PlaceName": uniquePlaceNames,
                "StateDescription": uniqueStateDescription
            });

            const updatedTables = res.data.map((data) => {
                if (data.StateDescription === "Closed Scheduled Open") {
                    data.StateDescription = "Closed During Regular Hours";
                }
                if (data.StateDescription === "Open Scheduled Closed") {
                    data.StateDescription = "Open Outside Regular Hours";
                }
                data.ActualOpenState = data.ActualOpenState ? "Open" : "Closed";
                data.ScheduledOpenState = data.ScheduledOpenState ? "Open" : "Closed";
                return data;
            });

            setTable(updatedTables);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    useEffect(() => {
        const calculateScrollHeight = () => {
            const viewportHeight = window.innerHeight -178;
            const calculatedHeight = viewportHeight; 
            setScrollHeight(`${calculatedHeight}px`);
        };

        calculateScrollHeight(); 

        window.addEventListener('resize', calculateScrollHeight);

        return () => {
            window.removeEventListener('resize', calculateScrollHeight);
        };
    }, [])
    useEffect(() => {
        fetchConcessionsGateData();
        const intervalId = setInterval(fetchConcessionsGateData, 10000); // 10 seconds
        return () => clearInterval(intervalId);
    }, [filters]); 

    const rowClassName = (rowData) => {
        if (rowData.StateDescription === "Closed Scheduled Open" || rowData.StateDescription === "Closed During Regular Hours") {
            return 'row-red'; 
        }
    };

    const DropdownFilterTemplate = (filter) => {
        let opts = options[filter.field];
        return (
            <Dropdown value={filters[filter.field].value}
                options={opts}
                onChange={(e) => {
                    const newFilters = { ...filters };
                    newFilters[filter.field].value = e.value;
                    setFilters(newFilters);
                    filter.filterApplyCallback(e.value);
                }}
                //style={{minWidth:'250px'}}
                showClear
            />
        );
    };

    return (
        <div id="ConcessionsGateNow">
            <div className="card">
                <DataTable value={table} sortField='SQLTenantName' sortOrder={1} rowClassName={rowClassName} filters={filters} filterDisplay='row' showGridlines className="gate-now-datatable" style={{maxHeight:scrollHeight}}>
                    <Column header="Tenant" field="SQLTenantName" sortable filter filterElement={DropdownFilterTemplate} />
                    <Column header="Unit" field="SQLUnitName" sortable filter filterElement={DropdownFilterTemplate} />
                    <Column header="Gate" field="PlaceName" sortable filter filterElement={DropdownFilterTemplate}  />
                    <Column header="Location" field="SQLLocationName" sortable filter filterElement={DropdownFilterTemplate}  />
                    <Column header="Scheduled" field="ScheduledOpenState" sortable />
                    <Column header="Actual" field="ActualOpenState" sortable />
                    <Column header="Status" field="IsDiscrepency" sortable body={(e)=> e.IsDiscrepency ? <i className="fa-solid fa-circle-xmark"/>:<i className="fa-solid fa-badge-check"></i>} /> 
                    <Column header="Status Description" field="StateDescription" filter filterElement={DropdownFilterTemplate} sortable  />
                    <Column header="Last Sensor Reading" field="Timestamp"
                        body={(rowData) => {
                            const utcDate = new Date(rowData.Timestamp);

                            // Convert UTC to EST
                            const estDate = toZonedTime(utcDate, 'America/New_York');
                            const formattedDate = format(estDate, 'yyyy/MM/dd hh:mm a');

                            // Get current date in EST
                            const currentDateEST = toZonedTime(new Date(), 'America/New_York');
                            const currentDateFormatted = format(currentDateEST, 'yyyy/MM/dd hh:mm a');

                            // Calculate the differences
                            const differenceMinutes = differenceInMinutes(currentDateEST, estDate);
                            const differenceSeconds = differenceInSeconds(currentDateEST, estDate)< 0? 0 : differenceInSeconds(currentDateEST, estDate);
                            const differenceHours = differenceInHours(currentDateEST, estDate);
                            const differenceDays = differenceInDays(currentDateEST, estDate);

                            switch(true){
                                case differenceMinutes >= 1440 : 
                                    return <div style={{color: 'red', fontWeight: 'bolder'}}>{differenceDays == 1? `${differenceDays} day ago`:`${differenceDays} days ago`}</div>;
                                case differenceMinutes >= 60 :
                                    return <div style={{color: 'red', fontWeight: 'bolder'}}>{differenceHours == 1? `${differenceHours} hour ago`:`${differenceHours} hours ago`}</div>;
                                case differenceMinutes <= 0 :
                                    return <div>{differenceSeconds == 1? `${differenceSeconds} second ago`:`${differenceSeconds} seconds ago`}</div>;
                                case differenceMinutes > 30 :
                                    return <div style={{color: 'red', fontWeight: 'bolder'}}>{differenceMinutes == 1? `${differenceMinutes} minute ago`:`${differenceMinutes} minutes ago`}</div>;
                            }
                            return <div>{differenceMinutes == 1? `${differenceMinutes} minute ago`:`${differenceMinutes} minutes ago`}</div>;
                        }}
                       
                        sortable
                    />
                    <Column header="Scheduled Open" field="StartTimeString"
                        body={(rowData) => {
                            const formattedTime = format(new Date(`2000-01-01T${rowData.StartTimeString}`), 'hh:mm a');
                            return formattedTime;
                        }}
                        
                        sortable
                    />
                    <Column header="Scheduled Close" field="EndTimeString"
                        body={(rowData) => {
                            const formattedTime = format(new Date(`2000-01-01T${rowData.EndTimeString}`), 'hh:mm a');
                            return formattedTime;
                        }}
                        
                        sortable
                    />
                </DataTable>
            </div>
        </div>
    );
};
